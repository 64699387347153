import { IIconProps } from '../interface';

const SvgYemen = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_17048)">
      <path
        d="M28 56.8359C43.464 56.8359 56 44.2999 56 28.8359C56 13.372 43.464 0.835938 28 0.835938C12.536 0.835938 0 13.372 0 28.8359C0 44.2999 12.536 56.8359 28 56.8359Z"
        fill="#F0F0F0"
      />
      <path
        d="M28.0001 56.8359C40.0391 56.8359 50.3023 49.2374 54.2585 38.575H1.7417C5.6979 49.2374 15.9611 56.8359 28.0001 56.8359Z"
        fill="black"
      />
      <path
        d="M28.0001 0.835938C15.9611 0.835938 5.6979 8.43444 1.7417 19.0969H54.2586C50.3023 8.43444 40.0391 0.835938 28.0001 0.835938Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_17048">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgYemen;
