import { IIconProps } from '../interface';

const SvgJapan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15910)">
      <path
        d="M28 56.4062C43.464 56.4062 56 43.8702 56 28.4062C56 12.9423 43.464 0.40625 28 0.40625C12.536 0.40625 0 12.9423 0 28.4062C0 43.8702 12.536 56.4062 28 56.4062Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 40.5801C34.7235 40.5801 40.1739 35.1297 40.1739 28.4062C40.1739 21.6828 34.7235 16.2324 28 16.2324C21.2766 16.2324 15.8262 21.6828 15.8262 28.4062C15.8262 35.1297 21.2766 40.5801 28 40.5801Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15910">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.40625)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgJapan;
