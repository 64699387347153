import { IIconProps } from '../interface';

const SvgTurksAndCaicos = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16871)">
      <path
        d="M56 28.418C56 43.8818 43.4639 56.418 28 56.418C12.5361 56.418 0 43.8818 0 28.418C0 28.4246 28 0.421031 28 0.417969C43.4639 0.417969 56 12.9541 56 28.418Z"
        fill="#0052B4"
      />
      <path
        d="M27.925 28.4179H28C28 28.3928 28 28.3683 28 28.343C27.975 28.3681 27.9501 28.393 27.925 28.4179Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 15.0266C28 10.0998 28 6.87164 28 0.417969H27.9953C12.5334 0.420594 0 12.9555 0 28.418H14.6087V20.1918L22.8349 28.418H27.9252C27.9501 28.393 27.9751 28.3681 28.0001 28.343C28.0001 26.4567 28.0001 24.7735 28.0001 23.2531L19.7737 15.0266H28Z"
        fill="#F0F0F0"
      />
      <path
        d="M14.1657 4.07013C9.7864 6.56377 6.14563 10.2044 3.6521 14.5837V28.418H10.9565V11.3747V11.3745H28C28 9.07076 28 6.87604 28 4.07013H14.1657Z"
        fill="#D80027"
      />
      <path
        d="M28 24.9748L18.0518 15.0268H14.6086V15.027L27.9998 28.418H28C28 28.418 28 26.0438 28 24.9748Z"
        fill="#D80027"
      />
      <path
        d="M31.6521 15.0266V28.4178C31.6521 34.941 40.1738 36.9397 40.1738 36.9397C40.1738 36.9397 48.6956 34.941 48.6956 28.4178V15.0266H31.6521Z"
        fill="#FFDA44"
      />
      <path
        d="M38.9565 19.8962C38.9565 21.241 37.8665 24.7658 36.5217 24.7658C35.1769 24.7658 34.0869 21.241 34.0869 19.8962C34.0869 18.5515 36.5217 17.4614 36.5217 17.4614C36.5217 17.4614 38.9565 18.5515 38.9565 19.8962Z"
        fill="#FF9811"
      />
      <path
        d="M45.4174 22.548C45.8102 21.5893 46.1158 20.188 46.1158 19.4904C46.1158 18.3698 44.6549 17.4614 44.6549 17.4614C44.6549 17.4614 43.194 18.3699 43.194 19.4904C43.194 20.188 43.4996 21.5893 43.8925 22.548L43.0491 24.4522C43.5448 24.6537 44.0866 24.7657 44.6548 24.7657C45.223 24.7657 45.7649 24.6537 46.2605 24.4522L45.4174 22.548Z"
        fill="#A2001D"
      />
      <path
        d="M38.348 28.418C38.348 28.418 37.1306 30.8528 37.1306 33.2876H43.2176C43.2174 30.8528 42.0001 28.418 42.0001 28.418L40.174 27.2006L38.348 28.418Z"
        fill="#6DA544"
      />
      <path
        d="M41.9999 28.418V27.8093C41.9999 26.8008 41.1823 25.9832 40.1738 25.9832C39.1653 25.9832 38.3477 26.8007 38.3477 27.8093V28.418H41.9999Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16871">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTurksAndCaicos;
