import { IIconProps } from '../interface';

const SvgVirginIslands = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_17031)">
      <path
        d="M28 56.8359C43.464 56.8359 56 44.2999 56 28.8359C56 13.372 43.464 0.835938 28 0.835938C12.536 0.835938 0 13.372 0 28.8359C0 44.2999 12.536 56.8359 28 56.8359Z"
        fill="#FCFCFC"
      />
      <path
        d="M32.7556 20.3972C32.7556 17.7709 30.6265 15.6418 28.0001 15.6418C25.3736 15.6418 23.2445 17.7709 23.2445 20.3972H12.7827C12.7827 22.9829 15.0352 25.0793 17.6209 25.0793H17.4652C17.4652 27.6653 19.5612 29.7618 22.1474 29.7618C22.1474 32.0513 23.7917 33.9538 25.9634 34.3604L25.9513 34.3878H30.049L30.0368 34.3604C32.2086 33.9536 33.8528 32.0512 33.8528 29.7618C36.4389 29.7618 38.5351 27.6653 38.5351 25.0793H38.3791C40.9648 25.0793 43.2174 22.9829 43.2174 20.3972H32.7556Z"
        fill="#FFDA44"
      />
      <path
        d="M25.9095 33.9026L22.9609 40.5595C24.5167 41.1919 26.2171 41.5434 28.0001 41.5434C29.783 41.5434 31.4834 41.192 33.0391 40.5595L30.0907 33.9026H25.9095Z"
        fill="#FFDA44"
      />
      <path
        d="M21.9131 22.7358V30.927C21.9131 35.5861 28 37.0139 28 37.0139C28 37.0139 34.087 35.5861 34.087 30.927V22.7358H21.9131Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.78991 29.033L10.082 38.2406L13.3398 29.033H15.8261L11.0764 41.2069H9.08745L4.30371 29.033H6.78991Z"
        fill="#338AF3"
      />
      <path
        d="M45.0435 41.2069V29.033H47.4096V41.2069H45.0435Z"
        fill="#338AF3"
      />
      <path
        d="M24.3479 25.3809V35.3071C25.1847 35.923 26.0851 36.3466 26.7827 36.6178V25.3809H24.3479Z"
        fill="#D80027"
      />
      <path
        d="M31.6521 25.3809V35.3071C30.8153 35.923 29.9149 36.3466 29.2173 36.6178V25.3809H31.6521Z"
        fill="#D80027"
      />
      <path
        d="M21.9131 22.7355H34.087V26.5982H21.9131V22.7355Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_17031">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgVirginIslands;
