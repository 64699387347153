export enum IconsListEnum {
  Circle = 'Circle',
  Check = 'Check',
  Plus = 'Plus',
  Minus = 'Minus',
  Close = 'Close',
  Pause = 'Pause',
  Play = 'Play',
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
  Question = 'Question',
  BurgerMenu = 'BurgerMenu',
  Globe = 'Globe',
  Home = 'Home',
  Heart = 'Heart',
  Ring = 'Ring',
  ChevronDown = 'ChevronDown',
  KeyEnter = 'KeyEnter',
  SuitCase = 'SuitCase',
  InvestimentAdd = 'InvestimentAdd',
  Chair = 'Chair',
  HouseHandshake = 'HouseHandshake',
  HouseKey = 'HouseKey',
  SuitCaseWorking = 'SuitCaseWorking',
  UserProfile = 'UserProfile',
  OfficeChairTable = 'OfficeChairTable',
  PlaneGlobe = 'PlaneGlobe',
  MapsNavigation = 'MapsNavigation',
  MapsNavigationMultiple = 'MapsNavigationMultiple',
  MoneyCoins = 'MoneyCoins',
  GroupUser = 'GroupUser',
  Bank = 'Bank',
  Chess = 'Chess',
  InvoiceHandSelect = 'InvoiceHandSelect',
  DocumentUpload = 'DocumentUpload',
  Document = 'Document',
  MoneyBankNoteArrow = 'MoneyBankNoteArrow',
  BabyChild = 'BabyChild',
  EngagementRings = 'EngagementRings',
  Romance = 'Romance',
  Message = 'Message',
  Logo = 'Logo',
  Barchart = 'Barchart',
  Subscription = 'Subscription',
  BankBuilding = 'BankBuilding',
  Car = 'Car',
  Membership = 'Membership',
  House = 'House',
  Doctor = 'Doctor',
  Insurance = 'Insurance',
  Crypto = 'Crypto',
  Browser = 'Browser',
  GlobePin = 'GlobePin',
  Contract = 'Contract',
  Entrepreneur = 'Entrepreneur',
  Employee = 'Employee',
  Other = 'Other',
  Leave = 'Leave',
  Google = 'Google',
}

export type IconsList = keyof typeof IconsListEnum;
