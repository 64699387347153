import { IIconProps } from '../interface';

const SvgHome = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.937 4.67056C16.3892 4.23229 15.6108 4.23229 15.063 4.67055L5.56296 12.2706C5.20713 12.5552 5 12.9862 5 13.4419V25.5C5 26.3284 5.67157 27 6.5 27H25.5C26.3284 27 27 26.3284 27 25.5V13.4419C27 12.9862 26.7929 12.5552 26.437 12.2706L16.937 4.67056ZM13.1889 2.32795C14.8324 1.01316 17.1676 1.01316 18.8111 2.32795L28.3111 9.92796C29.3786 10.7819 30 12.0748 30 13.4419V25.5C30 27.9853 27.9853 30 25.5 30H6.5C4.01472 30 2 27.9853 2 25.5V13.4419C2 12.0748 2.6214 10.7819 3.68887 9.92796L13.1889 2.32795Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgHome;
