import { IIconProps } from '../interface';

const SvgUruguay = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16953)">
      <path
        d="M28 56.418C43.464 56.418 56 43.8819 56 28.418C56 12.954 43.464 0.417969 28 0.417969C12.536 0.417969 0 12.954 0 28.418C0 43.8819 12.536 56.418 28 56.418Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 21.1136H55.0354C54.3333 18.5089 53.2663 16.0545 51.8903 13.8092H28V21.1136Z"
        fill="#338AF3"
      />
      <path
        d="M10.5703 50.331H45.4295C47.9848 48.2958 50.1763 45.8237 51.8902 43.0266H4.10962C5.82363 45.8235 8.01507 48.2958 10.5703 50.331Z"
        fill="#338AF3"
      />
      <path
        d="M28 0.417969V6.50491H45.4297C40.6467 2.69559 34.5897 0.417969 28 0.417969Z"
        fill="#338AF3"
      />
      <path
        d="M28 21.1136H55.0354C54.3333 18.5089 53.2663 16.0545 51.8903 13.8092H28V21.1136Z"
        fill="#338AF3"
      />
      <path
        d="M0 28.418C0 30.9453 0.336875 33.3933 0.964578 35.7224H55.0355C55.6631 33.3933 56 30.9453 56 28.418H0Z"
        fill="#338AF3"
      />
      <path
        d="M24.3479 16.8047L20.9282 18.4132L22.7491 21.7251L19.0359 21.0148L18.5653 24.7658L15.9791 22.007L13.3926 24.7658L12.9221 21.0148L9.20885 21.7249L11.0297 18.4131L7.61023 16.8047L11.0298 15.1963L9.20885 11.8844L12.922 12.5947L13.3927 8.84369L15.9791 11.6026L18.5654 8.84369L19.0359 12.5947L22.7493 11.8844L20.9283 15.1964L24.3479 16.8047Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16953">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgUruguay;
