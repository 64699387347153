import { IIconProps } from '../interface';

const SvgLesotho = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15994)">
      <path
        d="M0 28.8359C0 33.1989 0.998266 37.3287 2.77834 41.0098L28 42.2272L53.2217 41.0099C55.0017 37.3287 56 33.1989 56 28.8359C56 24.4729 55.0017 20.3432 53.2217 16.662L28 15.4446L2.77834 16.6619C0.998266 20.3432 0 24.4729 0 28.8359H0Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 56.836C39.1008 56.836 48.6926 50.3757 53.2216 41.0098H2.77832C7.30732 50.3757 16.8991 56.836 28 56.836Z"
        fill="#6DA544"
      />
      <path
        d="M28 0.835938C16.8991 0.835938 7.30732 7.29617 2.77832 16.6621H53.2215C48.6926 7.29617 39.1008 0.835938 28 0.835938Z"
        fill="#0052B4"
      />
      <path
        d="M29.8262 28.2273V21.5317H26.174V28.2273L21.7629 32.6385C23.0458 34.7385 25.3592 36.1403 28.0002 36.1403C30.6411 36.1403 32.9544 34.7385 34.2374 32.6385L29.8262 28.2273Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15994">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLesotho;
