import React, { ChangeEvent, MouseEvent } from 'react';
import Icon from '../Icon';
import { FlagsList } from '../Icon/flags/enum';
import { IconsList } from '../Icon/icons/enum';
import { colors } from '../theme';
import Typography from '../Typography';
import './index.css';

interface IPill {
  title?: string;
  name: string;
  disabled?: boolean;
  checked?: boolean;
  icon?: FlagsList | IconsList;
  radio?: boolean;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onClick: (event: MouseEvent<HTMLElement>) => void;
}

const Pill = ({
  title,
  name,
  icon,
  checked,
  radio,
  disabled,
  onChange,
  onClick,
}: IPill) => {
  const renderIcon = () => {
    if (icon && !checked) {
      return <Icon name={icon} width={20} disabled={disabled} />;
    }

    if (checked) {
      return (
        <Icon
          name="Check"
          type="sm"
          color={colors.base.white}
          width={12}
          height={12}
        />
      );
    }

    return null;
  };

  return (
    <button
      className={`pill h-spacing-48 py-spacing-14  
        flex items-center rounded-4xl 
        active:bg-neutral-fieldActive box-border ${
          disabled ? 'pointer-events-none' : 'pointer-events-auto'
        } ${
        checked
          ? 'checked pl-spacing-12 pr-spacing-20 bg-base-white hover:border-interactive-backgroundStrongHover'
          : 'pl-spacing-14 pr-spacing-24 bg-neutral-field hover:bg-neutral-fieldHover'
      } ${disabled ? 'disabled' : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={title && 'mr-spacing-10'}>
        {radio && (
          <>
            <input
              type="checkbox"
              name={name}
              defaultChecked={checked}
              onChange={onChange}
              disabled={disabled}
              className="peer absolute w-0 h-0"
            />
            <label
              className={`radio-pill transition-all w-spacing-20 h-spacing-20 rounded-full cursor-pointer flex align-center items-center ${
                checked ? 'checked' : 'unchecked'
              } ${disabled ? 'disabled' : ''} ${icon ? 'has-icon' : ''}`}
              htmlFor={name}
            >
              {renderIcon()}
            </label>
          </>
        )}
      </div>
      {title && (
        <Typography
          variant="LabelDefault500"
          colorType={disabled ? 'neutral' : 'base'}
          color={disabled ? 'textDisabled' : 'gray800'}
          pointer={!disabled}
        >
          {title}
        </Typography>
      )}
    </button>
  );
};

export default Pill;
