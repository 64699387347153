import { IIconProps } from '../interface';

const SvgKuwait = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15963)">
      <path
        d="M54.2584 38.575C55.3839 35.5417 56 32.2609 56 28.8359C56 25.411 55.3839 22.1301 54.2584 19.0968L28 16.662L1.74158 19.0968C0.616109 22.1301 0 25.411 0 28.8359C0 32.2609 0.616109 35.5417 1.74158 38.575L28 41.0098L54.2584 38.575Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 56.8359C40.0389 56.8359 50.3022 49.2374 54.2583 38.575H1.74146C5.69777 49.2374 15.9609 56.8359 28 56.8359Z"
        fill="#D80027"
      />
      <path
        d="M28 0.835938C15.9609 0.835938 5.69777 8.43444 1.74146 19.0969H54.2584C50.3022 8.43444 40.0389 0.835938 28 0.835938Z"
        fill="#6DA544"
      />
      <path
        d="M8.20099 9.03687C-2.73366 19.9715 -2.73366 37.7002 8.20099 48.635C11.0551 45.7808 13.8409 42.9951 18.2609 38.5751V19.0968L8.20099 9.03687Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15963">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgKuwait;
