import React, { MouseEvent } from 'react';
import Icon from '../Icon';
import Loading from './../Loading';
import { IconsList } from '../Icon/icons/enum';
import { colors } from '../theme';
import './index.css';
export interface IButton {
  children: string;
  size: 'default' | 'medium' | 'large' | 'xlarge';
  variant: 'primary' | 'secondary' | 'tertiary';
  disabled?: boolean;
  icon?: IconsList;
  iconSize?: 'default' | 'medium' | 'large';
  iconPosition?: 'right' | 'left';
  loading?: boolean;
  className?: string;
  onClick: (event: MouseEvent<HTMLElement>) => void;
}

const Button = ({
  children,
  size,
  variant,
  disabled,
  icon,
  iconSize,
  iconPosition = 'left',
  loading = false,
  className,
  onClick,
}: IButton) => {
  const handleIconColor = () => {
    if (disabled) {
      return colors.neutral.textDisabled;
    }

    if (variant === 'primary') {
      return colors.base.white;
    }

    return colors.interactive.text;
  };

  const getIconSize = () => {
    if (iconSize === 'default') {
      return 14;
    }

    if (iconSize === 'medium') {
      return 21;
    }

    if (iconSize === 'large') {
      return 32;
    }

    if (size === 'default') {
      return 14;
    }

    return 21;
  };

  return (
    <button
      className={`button ${className} ${variant} ${size} ${
        Boolean(disabled) && 'disabled'
      } ${Boolean(icon) && 'icon'} ${
        iconPosition === 'left' ? 'iconLeft' : 'iconRight'
      } ${
        loading ? 'pointer-events-none' : 'pointer-events-auto'
      } w-auto flex items-center justify-center rounded-lg transition-all`}
      disabled={disabled}
      onClick={onClick}
    >
      {icon ? (
        <div className="iconContainer">
          <Icon
            name={icon}
            color={handleIconColor()}
            width={getIconSize()}
            height={getIconSize()}
            type="circle"
          />
        </div>
      ) : (
        <></>
      )}
      <div className="absolute">
        <Loading
          variant={variant}
          active={loading && !disabled}
          width={size === 'default' ? 14 : 21}
          height={size === 'default' ? 14 : 21}
        />
      </div>
      <span className={`${loading && !disabled ? 'invisible' : ''}`}>
        {children}
      </span>
    </button>
  );
};

export default Button;
