import { IIconProps } from '../interface';

const SvgBankBuilding = ({
  width = 24,
  height = 24,
  color,
  type,
}: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          version="1.1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            strokeLinecap="round"
            strokeWidth="1.5"
            stroke={color}
            fill="none"
            strokeLinejoin="round"
          >
            <path d="M22,9l-2.62,1"></path>
            <path d="M4.63,10l-2.63,-1"></path>
            <path d="M18.25,14h-2.5"></path>
            <path d="M8.25,14h-2.5"></path>
            <path d="M19.379,10l-0.839,-4.012c-0.243,-1.158 -1.264,-1.988 -2.448,-1.988h-8.08c-1.161,0 -2.17,0.8 -2.434,1.931l-0.951,4.069"></path>
            <path d="M3.25,20h2.25c0.828,0 1.5,-0.672 1.5,-1.5v-1h10v1c0,0.828 0.672,1.5 1.5,1.5h2.25c0.69,0 1.25,-0.56 1.25,-1.25v-5.75c0,-1.657 -1.343,-3 -3,-3h-14c-1.657,0 -3,1.343 -3,3v5.75c0,0.69 0.56,1.25 1.25,1.25Z"></path>
          </g>
          <path fill="none" d="M0,0h24v24h-24Z"></path>
        </svg>
      );
  }
};

export default SvgBankBuilding;
