import { IIconProps } from '../interface';

const SvgJamaica = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15904)">
      <path
        d="M28 56.418C43.464 56.418 56 43.8819 56 28.418C56 12.954 43.464 0.417969 28 0.417969C12.536 0.417969 0 12.954 0 28.418C0 43.8819 12.536 56.418 28 56.418Z"
        fill="#FFDA44"
      />
      <path
        d="M45.0467 6.20609C40.3253 2.57692 34.4153 0.417969 28 0.417969C21.5847 0.417969 15.6745 2.57703 10.9531 6.20609L28 23.2528L45.0467 6.20609Z"
        fill="#6DA544"
      />
      <path
        d="M5.78802 11.3712C2.15906 16.0926 0 22.0027 0 28.418C0 34.8333 2.15906 40.7434 5.78813 45.4649L22.835 28.418L5.78802 11.3712Z"
        fill="black"
      />
      <path
        d="M10.9531 50.6298C15.6746 54.259 21.5847 56.4179 28 56.4179C34.4153 56.4179 40.3253 54.2589 45.0468 50.6298L28 33.5831L10.9531 50.6298Z"
        fill="#6DA544"
      />
      <path
        d="M50.2119 45.4647C53.8411 40.7433 56 34.8333 56 28.418C56 22.0027 53.8411 16.0926 50.2119 11.3712L33.1652 28.418L50.2119 45.4647Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15904">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgJamaica;
