import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
interface IProtectedRoute {
  isLoggedIn: boolean;
  children: ReactElement;
}
const Protected = ({ isLoggedIn, children }: IProtectedRoute) => {
  if (!isLoggedIn) {
    return <Navigate to={'/login'} replace />;
  }
  return children;
};

export default Protected;
