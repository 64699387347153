import { IIconProps } from '../interface';

const SvgTunisia = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16832)">
      <path
        d="M28 56.418C43.464 56.418 56 43.8819 56 28.418C56 12.954 43.464 0.417969 28 0.417969C12.536 0.417969 0 12.954 0 28.418C0 43.8819 12.536 56.418 28 56.418Z"
        fill="#D80027"
      />
      <path
        d="M28 41.8093C35.3958 41.8093 41.3913 35.8138 41.3913 28.4179C41.3913 21.0221 35.3958 15.0266 28 15.0266C20.6041 15.0266 14.6086 21.0221 14.6086 28.4179C14.6086 35.8138 20.6041 41.8093 28 41.8093Z"
        fill="#F0F0F0"
      />
      <path
        d="M29.6496 23.2977L31.947 26.4635L35.6676 25.2571L33.3666 28.4202L35.6637 31.586L31.9443 30.375L29.6434 33.5381L29.6458 29.6268L25.9265 28.4158L29.6472 27.2094L29.6496 23.2977Z"
        fill="#D80027"
      />
      <path
        d="M31.0434 36.331C26.6732 36.331 23.1303 32.7881 23.1303 28.418C23.1303 24.0478 26.6732 20.5049 31.0434 20.5049C32.406 20.5049 33.6882 20.8494 34.8076 21.456C33.0515 19.7387 30.6502 18.6788 27.9999 18.6788C22.6213 18.6788 18.2609 23.0391 18.2609 28.4179C18.2609 33.7966 22.6213 38.1569 27.9999 38.1569C30.6502 38.1569 33.0516 37.097 34.8076 35.3797C33.6882 35.9865 32.406 36.331 31.0434 36.331Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16832">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTunisia;
