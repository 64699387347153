import { Outlet } from 'react-router-dom';
import Sidebar from '../sidebar';

const Layout = () => {
  return (
    <>
      <div
        className="fixed"
        style={{
          height: '100vh',
        }}
      >
        <Sidebar variant="APP" />
      </div>
      <div className="pl-spacing-270">
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
