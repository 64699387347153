import { IIconProps } from '../interface';

const SvgKyrgyzstan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15968)">
      <path
        d="M28 56.8359C43.464 56.8359 56 44.2999 56 28.8359C56 13.372 43.464 0.835938 28 0.835938C12.536 0.835938 0 13.372 0 28.8359C0 44.2999 12.536 56.8359 28 56.8359Z"
        fill="#D80027"
      />
      <path
        d="M41.6934 28.8359L36.0983 31.4677L39.0778 36.8869L33.0017 35.7244L32.2321 41.8621L27.9998 37.3482L23.7679 41.8621L22.998 35.7244L16.9224 36.8867L19.9018 31.4675L14.3065 28.8359L19.9018 26.2043L16.9224 20.785L22.9978 21.9476L23.7681 15.8098L27.9998 20.3238L32.2323 15.8098L33.0017 21.9476L39.078 20.785L36.0984 26.2046L41.6934 28.8359Z"
        fill="#FFDA44"
      />
      <path
        d="M28 37.3577C32.7064 37.3577 36.5217 33.5424 36.5217 28.8359C36.5217 24.1295 32.7064 20.3142 28 20.3142C23.2936 20.3142 19.4783 24.1295 19.4783 28.8359C19.4783 33.5424 23.2936 37.3577 28 37.3577Z"
        fill="#D80027"
      />
      <path
        d="M23.7391 28.8359C23.5338 28.8359 23.3312 28.8464 23.1311 28.8663C23.1384 30.0466 23.5654 31.1269 24.2707 31.9661C24.6469 30.9537 25.2087 30.0304 25.9171 29.2403C25.2405 28.9801 24.5065 28.8359 23.7391 28.8359Z"
        fill="#FFDA44"
      />
      <path
        d="M26.3622 33.4224C26.8741 33.6053 27.4252 33.7056 28.0001 33.7056C28.5749 33.7056 29.1261 33.6053 29.638 33.4224C29.359 32.3273 28.7829 31.3497 28.0001 30.5815C27.2173 31.3496 26.6412 32.3273 26.3622 33.4224Z"
        fill="#FFDA44"
      />
      <path
        d="M32.217 26.4019C31.375 24.9466 29.8025 23.9664 28 23.9664C26.1975 23.9664 24.625 24.9465 23.7831 26.4019C25.3181 26.4097 26.7574 26.8271 28 27.5477C29.2426 26.8271 30.6818 26.4097 32.217 26.4019Z"
        fill="#FFDA44"
      />
      <path
        d="M30.0828 29.2404C30.7912 30.0304 31.3529 30.9537 31.7292 31.9663C32.4344 31.127 32.8614 30.0467 32.8688 28.8665C32.6686 28.8464 32.4659 28.8361 32.2607 28.8361C31.4936 28.836 30.7596 28.9801 30.0828 29.2404Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15968">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgKyrgyzstan;
