import { Suspense } from 'react';
import { Loading } from '../../design-system';

const LoadingComponents = ({
  children,
  variant,
}: {
  children: any;
  variant: 'FULL' | 'APP';
}) => {
  const styles = variant === 'APP' ? { width: 'calc(100% - 270px)' } : {};

  return (
    <Suspense
      fallback={
        <div
          className="flex fixed z-10 w-full h-full items-center justify-center bg-base-white"
          style={styles}
        >
          <Loading variant="secondary" active={true} width={60} height={60} />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

export default LoadingComponents;
