import React, { useEffect, useState } from 'react';
import Icon from '../../design-system/Icon';
import { Link } from 'react-router-dom';
import { IToolAnchorMenu, sidebarAppAnchors } from '../consts';
import { Anchor, Grid, ToolAnchor } from '../../design-system';
import Collapse from '../collapse';
import { useTranslation } from 'react-i18next';
import { changeQuestionById } from '../../reducers/sidebar';
import useSidebar from './hooks/useSidebar';
import { useDispatch } from 'react-redux';
import { User } from '@supabase/supabase-js';
import { supabase } from '../../supabaseClient';

interface ISidebar {
  variant: 'APP' | 'TOOL';
}

const Sidebar = ({ variant }: ISidebar) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [user, setUser] = useState<User | null>({} as User);
  useEffect(() => {
    const handleSession = async () => {
      const sessionData = await supabase.auth.getSession();
      setUser(sessionData?.data.session?.user ?? null);
    };

    handleSession();
  }, []);
  const { sidebar } = useSidebar();

  return (
    <div className="flex flex-col px-spacing-5 pt-spacing-24 pb-spacing-4 w-spacing-270 h-full">
      <div className="flex ml-spacing-16 mb-spacing-26">
        <Link to={'/'}>
          <Icon name="Logo" width={90} height={24} />
        </Link>
      </div>
      {variant === 'APP' ? (
        <>
          <div className="flex-1">
            {sidebarAppAnchors.map((anchor) => (
              <Anchor {...anchor} key={anchor.label} className="mb-spacing-8" />
            ))}
          </div>
          <>
            <Anchor
              label={'Ludwig Frank'}
              description={user?.email}
              avatar={'https://avatars.githubusercontent.com/u/98967193?v=4'}
              path="/profile"
            />
            <Grid
              noPadding
              fluid
              onClick={() => supabase.auth.signOut().catch(console.error)}
              className="px-spacing-18 my-spacing-20 cursor-pointer"
            >
              <Icon name="Leave" />
            </Grid>
          </>
        </>
      ) : (
        <>
          <div className="flex-1">
            {sidebar.map((anchor: IToolAnchorMenu, index) => {
              const anchorProps = {
                ...anchor,
                label: t(anchor.label),
              };

              return (
                <Collapse
                  header={<ToolAnchor {...anchorProps} />}
                  disabled={anchor.disabled}
                  className={
                    !anchor.done && !anchor.disabled ? 'py-spacing-16' : null
                  }
                  active={anchor.opened}
                  key={anchor.label + index}
                >
                  <div className="ml-spacing-16">
                    {anchor.questions?.map((question) => {
                      const questionProps = {
                        ...question,
                        label: t(question.label),
                        action: () =>
                          dispatch(
                            changeQuestionById({
                              category_id: anchor.id,
                              question_id: question.id,
                            })
                          ),
                      };

                      return (
                        <ToolAnchor key={question.label} {...questionProps} />
                      );
                    })}
                  </div>
                </Collapse>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
