import { configureStore } from '@reduxjs/toolkit';

import sidebarReducer, { IStateSidebar } from './reducers/sidebar';

export interface IRootState {
  sidebar: IStateSidebar;
}

export default configureStore({
  reducer: {
    sidebar: sidebarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
