import { IIconProps } from '../interface';

const SvgLogo = ({ width = 32, height = 32, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.9528 14.1273C22.4295 13.2897 22.7253 12.3565 22.8201 11.3909C22.9149 10.4254 22.8064 9.45017 22.502 8.53157C22.1976 7.61297 21.7045 6.77251 21.0562 6.0673C20.4078 5.3621 19.6194 4.80869 18.7445 4.44467C17.8697 4.08064 16.9288 3.91455 15.986 3.95767C15.0432 4.00079 14.1204 4.25211 13.2804 4.69456C12.4404 5.13701 11.7029 5.76021 11.118 6.52181C10.717 7.04401 10.3941 7.62287 10.1586 8.23909C10.0178 8.60752 9.53098 8.73853 9.256 8.45577L7.15009 6.29035C6.91504 6.04866 6.81258 5.70317 6.91148 5.38086C7.21094 4.40497 7.72379 3.50977 8.4134 2.76538C8.45248 2.72331 8.49193 2.68162 8.53175 2.6403C8.57156 2.59898 8.61211 2.55842 8.65339 2.5186C10.2189 0.971447 12.2871 0.0767275 14.4574 0.00772745C16.6278 -0.0612726 18.7458 0.700357 20.4012 2.14511C22.0567 3.58986 23.1319 5.61492 23.4186 7.82811C23.7052 10.0413 23.1829 12.2851 21.9528 14.125V14.1273Z"
          fill="#FF6948"
        />
        <path
          d="M21.9527 14.1273C21.249 15.1769 20.3379 16.062 19.2787 16.7246H19.2755C18.2847 17.3399 17.1856 17.7481 16.0411 17.9258C14.8966 18.1035 13.7293 18.0472 12.606 17.7602C12.4795 17.7196 12.3512 17.6836 12.2225 17.652C11.2731 17.4162 10.2846 17.4009 9.32869 17.6071C8.37283 17.8133 7.47364 18.2358 6.69647 18.8441C5.9193 19.4523 5.28366 20.231 4.83575 21.1234C4.56359 21.6657 4.36489 22.2417 4.24371 22.8354C4.15549 23.2677 3.59379 23.4757 3.28621 23.1594L0.891466 20.697C0.648341 20.447 0.549088 20.087 0.656355 19.7552C1.28218 17.8192 2.52988 16.1524 4.20241 15.0346C6.02835 13.8143 8.23025 13.3355 10.3803 13.6912C10.667 13.7383 10.9512 13.8004 11.2318 13.8771H11.2351H11.2406H11.2461H11.2521C11.3414 13.9058 11.4307 13.9335 11.5211 13.9554H11.5271C12.7276 14.2757 13.9916 14.2403 15.1733 13.8534C16.355 13.4665 17.4062 12.7438 18.2055 11.7689C19.0049 10.7939 19.5197 9.60632 19.6907 8.34329C19.8617 7.08026 19.6819 5.79326 19.1719 4.63086C19.9846 5.04078 20.7063 5.61867 21.292 6.32841C21.8776 7.03815 22.3146 7.86448 22.5756 8.75574C22.8365 9.647 22.9159 10.584 22.8087 11.5083C22.7016 12.4325 22.4101 13.3241 21.9527 14.1273Z"
          fill="#FF6948"
        />
        <path
          d="M21.9527 14.1273C21.249 15.1769 20.3379 16.062 19.2787 16.7246H19.2755C18.2847 17.3399 17.1856 17.7481 16.0411 17.9258C14.8966 18.1035 13.7293 18.0472 12.606 17.7602C12.4795 17.7196 12.3512 17.6836 12.2225 17.652C11.2731 17.4162 10.2846 17.4009 9.32869 17.6071C8.37283 17.8133 7.47364 18.2358 6.69647 18.8441C5.9193 19.4523 5.28366 20.231 4.83575 21.1234C4.56359 21.6657 4.36489 22.2417 4.24371 22.8354C4.15549 23.2677 3.59379 23.4757 3.28621 23.1594L0.891466 20.697C0.648341 20.447 0.549088 20.087 0.656355 19.7552C1.28218 17.8192 2.52988 16.1524 4.20241 15.0346C6.02835 13.8143 8.23025 13.3355 10.3803 13.6912C10.667 13.7383 10.9512 13.8004 11.2318 13.8771H11.2351H11.2406H11.2461H11.2521C11.3414 13.9058 11.4307 13.9335 11.5211 13.9554H11.5271C12.7276 14.2757 13.9916 14.2403 15.1733 13.8534C16.355 13.4665 17.4062 12.7438 18.2055 11.7689C19.0049 10.7939 19.5197 9.60632 19.6907 8.34329C19.8617 7.08026 19.6819 5.79326 19.1719 4.63086C19.9846 5.04078 20.7063 5.61867 21.292 6.32841C21.8776 7.03815 22.3146 7.86448 22.5756 8.75574C22.8365 9.647 22.9159 10.584 22.8087 11.5083C22.7016 12.4325 22.4101 13.3241 21.9527 14.1273Z"
          fill="url(#paint0_linear_791_13039)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_791_13039"
            x1="19.4977"
            y1="5.00128"
            x2="15.4961"
            y2="16.5034"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9B1C00" />
            <stop offset="1" stopColor="#FF6948" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>;
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width || 107}
          height={height || 24}
          viewBox="0 0 107 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 14.2467C18.5 15.3467 21.5 7.9967 17 4.4967L19.5 2.4967C26.5 7.4967 22.1327 20.4232 12.5 18.4967C7.81288 17.5593 6.20143 18.3794 3.95868 23.0164C3.73067 23.4879 3.10501 23.6017 2.73473 23.2314L0.509654 21.0064C0.205293 20.702 0.128866 20.2347 0.330454 19.8544C3.23169 14.3811 6.34353 12.9154 13 14.2467Z"
            fill="#FF6948"
          />
          <path
            d="M13 14.2467C18.5 15.3467 21.5 7.9967 17 4.4967L19.5 2.4967C26.5 7.4967 22.1327 20.4232 12.5 18.4967C7.81288 17.5593 6.20143 18.3794 3.95868 23.0164C3.73067 23.4879 3.10501 23.6017 2.73473 23.2314L0.509654 21.0064C0.205293 20.702 0.128866 20.2347 0.330454 19.8544C3.23169 14.3811 6.34353 12.9154 13 14.2467Z"
            fill="url(#paint0_linear_1318_37939)"
          />
          <path
            d="M21.4525 14.6238C21.9293 13.7863 22.2251 12.8531 22.3199 11.8875C22.4146 10.922 22.3061 9.94675 22.0018 9.02815C21.6974 8.10955 21.2043 7.26909 20.5559 6.56389C19.9076 5.85868 19.1192 5.30527 18.2443 4.94125C17.3694 4.57723 16.4286 4.41113 15.4858 4.45425C14.5429 4.49737 13.6201 4.7487 12.7802 5.19114C11.9402 5.63359 11.2027 6.25679 10.6178 7.0184C10.2167 7.54059 9.89385 8.11945 9.65834 8.73567C9.51752 9.1041 9.03074 9.23512 8.75575 8.95236L6.64985 6.78693C6.4148 6.54524 6.31233 6.19975 6.41123 5.87745C6.71069 4.90155 7.22355 4.00635 7.91315 3.26196C7.95224 3.21989 7.99169 3.1782 8.0315 3.13688C8.07132 3.09556 8.11187 3.055 8.15315 3.01518C9.71867 1.46803 11.7869 0.573309 13.9572 0.504309C16.1275 0.435309 18.2455 1.19694 19.901 2.64169C21.5565 4.08644 22.6317 6.1115 22.9183 8.32469C23.2049 10.5379 22.6826 12.7817 21.4525 14.6216V14.6238Z"
            fill="#FF6948"
          />
          <path
            d="M30 22.342H33.276V16.31C34.03 17.246 35.434 17.818 36.942 17.818C40.79 17.818 43.26 15.062 43.26 11.058C43.26 7.05399 40.946 4.29799 37.176 4.29799C35.434 4.29799 34.004 4.99999 33.276 6.01399V4.50599H30V22.342ZM36.552 14.984C34.446 14.984 33.068 13.424 33.068 11.058C33.068 8.69199 34.446 7.13199 36.552 7.13199C38.632 7.13199 40.01 8.69199 40.01 11.058C40.01 13.424 38.632 14.984 36.552 14.984Z"
            fill="#272B35"
          />
          <path
            d="M50.4854 4.29799C47.7034 4.29799 45.3894 5.96199 44.9734 8.56199L47.8854 9.08199C48.0414 7.67799 49.0554 6.79399 50.4854 6.79399C52.0714 6.79399 53.0854 7.70399 53.0854 9.18599V9.31599L50.6414 9.54999C46.7414 9.96599 44.7914 11.344 44.7914 13.918C44.7914 16.154 46.5854 17.818 49.1594 17.818C50.7714 17.818 52.3574 16.986 53.0854 15.712V17.61H56.2574V9.62799C56.2574 6.14399 53.9174 4.29799 50.4854 4.29799ZM49.8874 15.4C48.6914 15.4 47.9634 14.724 47.9634 13.814C47.9634 12.878 48.8474 12.15 50.5894 11.942L53.0854 11.656V11.942C53.0854 13.866 51.9154 15.4 49.8874 15.4Z"
            fill="#272B35"
          />
          <path
            d="M66.721 7.54799L66.955 4.37599C66.955 4.37599 66.617 4.29799 66.175 4.29799C64.589 4.29799 63.081 5.38999 62.483 6.89799V4.50599H59.207V17.61H62.483V12.8C62.483 8.89999 64.017 7.44399 65.837 7.44399C66.357 7.44399 66.721 7.54799 66.721 7.54799Z"
            fill="#272B35"
          />
          <path
            d="M77.2935 17.48L77.0595 14.62C76.5915 14.854 76.0195 14.932 75.5255 14.932C74.5115 14.932 73.8355 14.256 73.8355 13.008V7.26199H77.1375V4.50599H73.8355V0.65799H70.5595V4.50599H68.3495V7.26199H70.5595V13.32C70.5595 16.388 72.5355 17.818 75.1875 17.818C75.9415 17.818 76.6435 17.714 77.2935 17.48Z"
            fill="#272B35"
          />
          <path
            d="M85.1809 17.818C89.1589 17.818 92.0189 14.958 92.0189 11.058C92.0189 7.15799 89.1589 4.29799 85.1809 4.29799C81.2029 4.29799 78.3429 7.15799 78.3429 11.058C78.3429 14.958 81.2029 17.818 85.1809 17.818ZM85.1809 14.984C83.0489 14.984 81.6449 13.398 81.6449 11.058C81.6449 8.71799 83.0489 7.13199 85.1809 7.13199C87.2869 7.13199 88.7169 8.71799 88.7169 11.058C88.7169 13.398 87.2869 14.984 85.1809 14.984Z"
            fill="#272B35"
          />
          <path
            d="M106.452 4.50599H103.176V10.252C103.176 13.19 102.032 14.906 100.004 14.906C98.4704 14.906 97.5084 13.84 97.5084 12.306V4.50599H94.2324V13.086C94.2324 15.946 96.1824 17.818 99.0164 17.818C100.862 17.818 102.344 16.908 103.176 15.634V17.61H106.452V4.50599Z"
            fill="#272B35"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1318_37939"
              x1="19.4929"
              y1="2.9078"
              x2="14.7758"
              y2="15.443"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9B1C00" />
              <stop offset="1" stopColor="#FF6948" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );
  }
};

export default SvgLogo;
