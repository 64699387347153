import { IIconProps } from '../interface';

const SvgSuitCase = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V10.2632C13.4622 10.3448 14.036 10.7704 14.3733 11.3235C14.589 11.6771 14.4772 12.1386 14.1235 12.3543C13.7699 12.57 13.3084 12.4582 13.0927 12.1045C12.9526 11.8749 12.7383 11.749 12.5 11.749H12.0392C12.0262 11.7497 12.0132 11.75 12 11.75C11.9868 11.75 11.9738 11.7497 11.9608 11.749H11.404C11.0429 11.749 10.75 12.0416 10.75 12.403C10.75 12.7026 10.9545 12.9639 11.2442 13.0365L13.1211 13.5079C14.0761 13.7498 14.75 14.6089 14.75 15.598C14.75 16.7351 13.8685 17.6676 12.75 17.7466V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V17.7378C10.5355 17.6561 9.96184 17.2291 9.62396 16.676C9.40804 16.3225 9.51955 15.8609 9.87303 15.645C10.2265 15.429 10.6881 15.5405 10.904 15.894C11.0456 16.1258 11.2605 16.252 11.5 16.252H11.9449C11.9631 16.2507 11.9815 16.25 12 16.25C12.0185 16.25 12.0369 16.2507 12.0551 16.252H12.596C12.9574 16.252 13.25 15.9591 13.25 15.598C13.25 15.2993 13.0461 15.0365 12.7534 14.9622L10.8798 14.4915C9.92374 14.2519 9.25 13.3912 9.25 12.403C9.25 11.2643 10.1329 10.3333 11.25 10.2544V10C11.25 9.58579 11.5858 9.25 12 9.25Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.826 2.75C8.30679 2.75 7.076 3.98079 7.076 5.5V6.75H5C3.48079 6.75 2.25 7.98079 2.25 9.5V18.5C2.25 20.0192 3.48079 21.25 5 21.25H19C20.5192 21.25 21.75 20.0192 21.75 18.5V9.5C21.75 7.98079 20.5192 6.75 19 6.75H16.924V5.5C16.924 3.98079 15.6932 2.75 14.174 2.75H9.826ZM15.424 6.75V5.5C15.424 4.80921 14.8648 4.25 14.174 4.25H9.826C9.13522 4.25 8.576 4.80921 8.576 5.5V6.75H15.424ZM19 8.25C19.6908 8.25 20.25 8.80921 20.25 9.5V18.5C20.25 19.1908 19.6908 19.75 19 19.75H5C4.30921 19.75 3.75 19.1908 3.75 18.5V9.5C3.75 8.80921 4.30921 8.25 5 8.25H19Z"
            fill={color}
          />
        </svg>
      );
  }
};

export default SvgSuitCase;
