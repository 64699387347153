import React, { useState, ReactElement, useEffect } from 'react';

interface ICollapse {
  header: ReactElement;
  children: ReactElement;
  disabled?: boolean;
  className?: string | null;
  active?: boolean;
}

const Collapse = ({
  header,
  children,
  disabled,
  className,
  active = false,
}: ICollapse) => {
  const [expanded, setExpanded] = useState<boolean>(active);
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    setExpanded(active);
  }, [active]);
  return (
    <div className={`h-auto ${className} ${disabled && 'pointer-events-none'}`}>
      <div onClick={handleToggle}>{header}</div>
      <div
        className={`transition-all duration-200 ease-linear overflow-hidden ${
          expanded ? 'max-h-spacing-500 opacity-1' : 'max-h-0 opacity-0'
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
