const en = {
  selectCountry: 'Select a country',
  searchCountry: 'Search country',
  noCountryFound: 'No country was found',
  sidebar: {
    general: {
      label: 'General',
      name_and_email: 'Name and Email',
      address: 'Address',
      family: 'Family',
      personal_details: 'Personal Details',
    },
    countries: {
      label: 'Countries',
    },
    wrapping_up: {
      label: 'Wrapping up',
      name_and_email: 'Name and Email',
      address: 'Address',
      family: 'Family',
      personal_details: 'Personal Details',
    },
  },
  questions: {
    fiscal_year: {
      label: 'Fiscal Year',
      description:
        'Let’s now figure out your country of <span data-tooltip="This is the country where you usually stay, this likely is the country with the most economical and personal ties, a place to stay, and other factors that indicate your intention is to be there for the longer term.">tax residence</span> with a few simple questions. We will ask some more questions about {{countries}} after you’ve given us some basic info about your situation. This will help us determine what to actually ask from you.',
      title:
        'For what <span data-tooltip="the calendar year">year</span> are you filling out the Tax Residence questionnaire?',
      answers: [
        { label: '2021', description: 'Tax Year' },
        { label: '2020', description: 'Tax Year' },
        { label: '2019', description: 'Tax Year' },
        { label: '2018', description: 'Tax Year' },
        { label: '2017', description: 'Tax Year' },
      ],
    },
    countries: {
      label: 'Countries',
      title:
        'Let’s now figure out your country of tax residence with a few simple questions.',
      description:
        'For the calendar year {{fiscalYear}}, in which countries did you <span data-tooltip="take into account any type of stay, regardless of the reason (personal, professional or otherwise). It does not matter if the days were in a row or spread out over the year.">stay</span> more than 30 days?',
      answers: [],
    },
    passport: {
      label: 'Passport',
      title: 'From which country / countries do you hold a passport?',
      description:
        "If you don't hold any passport, select in which country / countries you have citizenship.",
      answers: [
        { label: 'Brazil' },
        { label: 'United States' },
        { label: 'Spain' },
        { label: 'Portugal' },
      ],
    },
    immigration: {
      label: 'Immigration',
      title:
        'Did you <span data-tooltip="‘Immigrating’ means that you leave one country behind and start living in another country, with the intention to make it your new home">imigrate</span> to another country in {{fiscalYear}} between {{earliestDate}} and {{latestDate}}?',
      description:
        'Examples that indicate immigrating are terminating your rental contract or renting out / selling your home, letting the local authorities by de-registering as a resident, terminating memberships (gym, club, library, etc.), and selling your car. And in the other country you would do the opposite: you buy or rent a home, you start memberships, you take out subscriptions etc.',
      lightbox: {
        title: 'Why is this important?',
        text: 'Why is this important?',
      },
      answers: [{ label: 'Yes' }, { label: 'No' }],
      aditionalQuestions: [
        {
          label: 'Date',
          title: 'What was the (approximate) date you moved?',
          answers: [
            {
              label: 'Enter date',
              error: 'Invalid date',
              notBetweenError:
                'Must be between {{earliestDate}} and {{latestDate}}',
            },
          ],
        },
        {
          label: 'From',
          title: 'From which country did you move?',
          answers: [],
        },
        {
          label: 'To',
          title: 'Which country did you move to?',
          answers: [],
        },
      ],
    },
    previous_tax_return: {
      label: 'Previous Tax Return',
      title:
        'In which country did you file a tax resident <span data-tooltip="You filed a tax resident tax return in a country if that country was your country of tax residence.">tax return</span> for the year {{fiscalYear}}?',
      lightbox: {
        title: 'Why does this matter?',
        text: 'Why does this matter?',
      },
      answers: [{ label: 'I have not filed (yet)' }],
    },
    irregular_fy: {
      label: 'Irregular FY',
      title:
        'The fiscal year of {{country}} is not the same as the calendar year. Please select and / or verify which fiscal year you were a tax resident of {{country}}.',
      answers: [
        { label: 'The year {{fiscalYear}} from {{fromDate}} to {{toDate}}' },
      ],
    },
    historic_ties: {
      label: 'Historical Ties',
      title:
        'Looking at your entire life, with which country do you have the <span data-tooltip="Other examples that indicate a historical relationship are where you went to high-school, where you speak the language as a native speaker, where you have built your career, and where you have most frequently filed your tax returns as a tax resident.">strongest historical relationships</span>?',
      description:
        'This is usually the country you call your true home; where you were raised and / or spent the most years.',
      answers: [],
    },
    permanent_home: {
      label: 'Permanent home',
      title_irregular:
        'In which country or countries did you have a <span data-tooltip="Examples are: a house you own without having renters in it, or a house / apartment that you rent long-term, a shared house where you always have a room at your disposal, or a second home that is always available to you.">permanent home</span> between {{earliestDate}} and {{latestDate}}?',
      title_regular:
        'In which country or countries did you have a <span data-tooltip="Examples are: a house you own without having renters in it, or a house / apartment that you rent long-term, a shared house where you always have a room at your disposal, or a second home that is always available to you.">permanent home</span> in the {{fiscalYear}}?',
      description:
        'A permanent home is a place where you can stay that is always available to you.',
      answers: [
        { label: "I didn't have a permanent home between theses dates" },
      ],
    },
    stay: {
      label: 'Stay',
      title_regular:
        'How long did you <span data-tooltip="All the days combined in a year. Sum up all the days you visited the country">stay</span> in {{country}} in {{fiscalYear}}?',
      title_irregular:
        'How long did you <span data-tooltip="All the days combined in a year. Sum up all the days you visited the country">stay</span> in {{country}} in {{fiscalYear}} between {{earliestDateByCountry}} and {{latestDateByCountry}}?',
      answers: [
        { label: 'I didn’t stay here' },
        { label: '6 months or less (183 days)' },
        { label: 'More than 6 months' },
      ],
      aditionalQuestions: [
        {
          label: 'How many months',
          title: 'Let’s narrow that down. Approximately how many months?',
          answers: [
            { label: 'less than 2 months' },
            { label: '2 months' },
            { label: '3 months' },
            { label: '4 months' },
            { label: '5 months' },
            { label: '6 months' },
          ],
        },
        {
          label: 'How many days',
          title_irregular:
            'How many days have you stayed in {{country}} between {{start}} - {{end}}?',
          title_regular:
            'How many days have you stayed in {{country}} in {{fiscalYear}}?',
          description:
            'As you answered approximately 6 months, it could be important to know the exact number of days in determining where you have your tax residence.',
          answers: [
            { label: 'Less than 183 days' },
            { label: '183 days' },
            { label: 'More than 183 days' },
            { label: 'I don’t know exactly' },
          ],
        },
      ],
    },
    recap: {
      label: 'Recap',
      title: 'Recap',
      text1:
        'Looking at the calendar year {{fiscalYear}}, the fiscal years of one or more countries have not ended yet. FY that started in the second half of {{fiscalYear}} and that have not finished yet will be excluded. These are: ',
      text2:
        'For fiscal years starting in the second half of a calendar year we cannot have enough information to make an accurate assessment.',
      text3:
        'Now we will ask you questions specifically about the following countries:',
      country_to_be_excluded: 'FY {{fiscalYear}} for {{country}}.',
      lightbox: {
        title: 'Why include or exclude fiscal years?',
        text: 'Why include or exclude fiscal years?',
      },
    },
    employment: {
      label: 'Employment',
      title:
        'Did you work for an <span data-tooltip="Examples are working as an intern on payroll, being seconded to {{country}}, working for a Starbucks subsidiary registered in {{country}}, or a local small business.">employer</span> who is situated in {{country}}, while you were staying there?',
      description:
        'Even if you only worked for part of your stay for an employer in this country you should answer yes.',
      answers: [{ label: 'Yes' }, { label: 'No' }],
    },
    ties: {
      label: 'Ties',
      title: 'What are your ties to {{country}}? (select all that apply)',
      description:
        'Only select the ties that you had in the fiscal year that is of your interest. You should select any type of tie you had in {{country}} even if it was for part of the year.',
      answers: [
        { label: 'household', description: 'partner, children' },
        { label: 'other relatives', description: 'siblings, cousins' },
        {
          label: 'membership',
          description: 'gym, political party',
        },
        {
          label: 'subscriptions',
          description: 'mobile plan, newspaper',
        },
        { label: 'bank account', description: 'debit, savings' },
        { label: 'vehicle', description: 'car, motor' },
        { label: 'family doctor', description: 'GP, dentist' },
        { label: 'insurance', description: 'health, all-risk' },
      ],
    },
    residency: {
      label: 'Residency',
      title_regular:
        'Did you officially register as a <span data-tooltip="You do this by letting the authorities know that your address of permanent residence is in this country. Usually through an online portal, when getting a residence card, or when applying for a social security number.">resident</span> in {{country}} in {{year}}?',
      title_irregular:
        'Did you officially register as a <span data-tooltip="You do this by letting the authorities know that your address of permanent residence is in this country. Usually through an online portal, when getting a residence card, or when applying for a social security number.">resident</span> in {{country}} between {{earliestDateByCountry}} and {{latestDateByCountry}}?',
      answers: [{ label: 'Yes' }, { label: 'No' }],
    },
    visa: {
      label: 'Visa',
      title: 'What type of visa did, or still do, you have in {{country}}?',
      description: 'If you didn’t need a visa, select ‘no visa’',
      answers: [
        { label: 'Immigrant or permanent visa' },
        { label: 'Tourist visa, non-immigrant visa, or other temporary visa' },
        { label: 'No visa' },
      ],
    },
    personal_ties: {
      label: 'Personal Ties',
      title:
        'Between these countries, with which country do you have the strongest personal and economic ties?',
      answers: [
        {
          label: 'None in particular',
        },
      ],
    },
    permanent_home_country: {
      label: 'Permanent home',
      title_regular:
        'Did you have a <span data-tooltip="Examples are: a house you own without having renters in it, or a house / apartment that you rent long-term, a shared house where you always have a room at your disposal, or a second home that is always available to you.">permanent home</span> in {{country}} between {{earliestDateByCountry}} and {{latestDateByCountry}}?',
      title_irregular:
        'Did you have a <span data-tooltip="Examples are: a house you own without having renters in it, or a house / apartment that you rent long-term, a shared house where you always have a room at your disposal, or a second home that is always available to you.">permanent home</span> in {{country}} in {{fiscalYear}}?',
      description:
        'A permanent home is a place where you can stay that is always available to you.',
      answers: [{ label: 'Yes' }, { label: 'No' }],
    },
    personal_description: {
      label: 'Personal Description',
      title:
        'To help people like you and me, can you tell us a bit more about yourself?',
      description: 'What best describes you? (select all that apply)',
      answers: [
        { label: 'digital nomad' },
        { label: 'entrepreneur' },
        { label: 'expat' },
        { label: 'startup employee' },
        { label: 'tax optimizer' },
        { label: 'crypto investor' },
        { label: 'other (please enter)' },
      ],
    },
    detailed_description: {
      label: 'Detailed Description',
      title: 'As you selected “other”, please enter how you describe yourself:',
      description: 'What best describes you?',
      answers: [{ label: 'Other' }],
    },
  },
  countries: {
    AW: 'Aruba',
    AF: 'Afghanistan',
    AO: 'Angola',
    AI: 'Anguilla',
    AX: 'Åland Islands',
    AL: 'Albania',
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AR: 'Argentina',
    AM: 'Armenia',
    AS: 'American Samoa',
    AQ: 'Antarctica',
    TF: 'French Southern Territories',
    AG: 'Antigua and Barbuda',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BI: 'Burundi',
    BE: 'Belgium',
    BJ: 'Benin',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BF: 'Burkina Faso',
    BD: 'Bangladesh',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BS: 'Bahamas',
    BA: 'Bosnia and Herzegovina',
    BL: 'Saint Barthélemy',
    BY: 'Belarus',
    BZ: 'Belize',
    BM: 'Bermuda',
    BO: 'Bolivia',
    BR: 'Brazil',
    BB: 'Barbados',
    BN: 'Brunei Darussalam',
    BT: 'Bhutan',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    CF: 'Central African Republic',
    CA: 'Canada',
    CC: 'Cocos (Keeling) Islands',
    CH: 'Switzerland',
    CL: 'Chile',
    CN: 'China',
    CI: "Côte d'Ivoire",
    CM: 'Cameroon',
    CD: 'Congo, The Democratic Republic of the',
    CG: 'Congo',
    CK: 'Cook Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CV: 'Cabo Verde',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CW: 'Curaçao',
    CX: 'Christmas Island',
    KY: 'Cayman Islands',
    CY: 'Cyprus',
    CZ: 'Czechia',
    DE: 'Germany',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DK: 'Denmark',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EG: 'Egypt',
    ER: 'Eritrea',
    EH: 'Western Sahara',
    ES: 'Spain',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands (Malvinas)',
    FR: 'France',
    FO: 'Faroe Islands',
    FM: 'Micronesia, Federated States of',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GE: 'Georgia',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GM: 'Gambia',
    GW: 'Guinea-Bissau',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GD: 'Grenada',
    GL: 'Greenland',
    GT: 'Guatemala',
    GF: 'French Guiana',
    GU: 'Guam',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Heard Island and McDonald Islands',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IE: 'Ireland',
    IR: 'Iran',
    IQ: 'Iraq',
    IS: 'Iceland',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JE: 'Jersey',
    JO: 'Jordan',
    JP: 'Japan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KN: 'Saint Kitts and Nevis',
    KR: 'Korea, Republic of',
    KW: 'Kuwait',
    LA: 'Laos',
    LB: 'Lebanon',
    LR: 'Liberia',
    LY: 'Libya',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    MO: 'Macao',
    MF: 'Saint Martin (French part)',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova, Republic of',
    MG: 'Madagascar',
    MV: 'Maldives',
    MX: 'Mexico',
    MH: 'Marshall Islands',
    MK: 'North Macedonia',
    ML: 'Mali',
    MT: 'Malta',
    MM: 'Myanmar',
    ME: 'Montenegro',
    MN: 'Mongolia',
    MP: 'Northern Mariana Islands',
    MZ: 'Mozambique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MQ: 'Martinique',
    MU: 'Mauritius',
    MW: 'Malawi',
    MY: 'Malaysia',
    YT: 'Mayotte',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NU: 'Niue',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NZ: 'New Zealand',
    OM: 'Oman',
    PK: 'Pakistan',
    PA: 'Panama',
    PN: 'Pitcairn',
    PE: 'Peru',
    PH: 'Philippines',
    PW: 'Palau',
    PG: 'Papua New Guinea',
    PL: 'Poland',
    PR: 'Puerto Rico',
    KP: 'North Korea',
    PT: 'Portugal',
    PY: 'Paraguay',
    PS: 'Palestine',
    PF: 'French Polynesia',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SD: 'Sudan',
    SN: 'Senegal',
    SG: 'Singapore',
    GS: 'South Georgia and the South Sandwich Islands',
    SH: 'Saint Helena',
    SJ: 'Svalbard and Jan Mayen',
    SB: 'Solomon Islands',
    SL: 'Sierra Leone',
    SV: 'El Salvador',
    SM: 'San Marino',
    SO: 'Somalia',
    PM: 'Saint Pierre and Miquelon',
    RS: 'Serbia',
    SS: 'South Sudan',
    ST: 'Sao Tome and Principe',
    SR: 'Suriname',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SE: 'Sweden',
    SZ: 'Eswatini',
    SX: 'Sint Maarten (Dutch part)',
    SC: 'Seychelles',
    SY: 'Syria',
    TC: 'Turks and Caicos Islands',
    TD: 'Chad',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TM: 'Turkmenistan',
    TL: 'Timor-Leste',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TV: 'Tuvalu',
    TW: 'Taiwan, Province of China',
    TZ: 'Tanzania, United Republic of',
    UG: 'Uganda',
    UA: 'Ukraine',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    US: 'United States',
    UZ: 'Uzbekistan',
    VA: 'Vatican',
    VC: 'Saint Vincent and the Grenadines',
    VE: 'Venezuela',
    VG: 'British Virgin Islands',
    VI: 'U.S. Virgin Islands',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    YE: 'Yemen',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    XK: 'Kosovo',
  },
  buttons: {
    continue: 'Continue',
    finish: 'Submit',
  },
  app: {
    home: {
      title: 'Your tax home',
      description:
        'Let’s start by choosing the tax year you would like to start with.\nFrom there we will guide you through all the steps needed to save\nyou the most money and tailor make your returns.',
      box_title: 'Which tax year would you like to start with?',
      button: 'Continue with',
    },
    home_answersed: {
      title_inbox: 'Smart Inbox',
      title_deadlines: 'Deadlines',
      title_filings: 'Open Filings',
      button: 'Add a new year',
    },
    login: {
      title: 'Log in',
      emailPlaceholder: 'E-mail',
      passwordPlaceholder: 'Password',
      forgotPassword: 'Forgot password',
      loginButton: 'Login',
      quicklyAccess: 'or access quickly',
      googleSignIn: 'Sign-in with Google',
      noAccount: "Don't have an account?",
    },
    register: {
      title: 'Register',
      emailPlaceholder: 'E-mail',
      passwordPlaceholder: 'Password',
      signUpButton: 'Sign-up',
      haveAnAccount: 'Already have an account? Log in',
      quicklyAccess: 'or access quickly',
      googleSignIn: 'Sign-in with Google',
    },
    forgotPassword: {
      title: 'Need a new password?',
      emailSent: 'A reset password link was sent to',
      emailPlaceholder: 'E-mail',
      button: 'Get a new password',
      haveAnAccount: 'Already have an account? Log in',
      quicklyAccess: 'or access quickly',
    },
    resetPassword: {
      toast: {
        invalidToken: 'Sorry, Invalid token',
        invalidAccessToken: 'Invalid access token or type',
        passwordChanged: 'Password Changed',
        error: 'Sorry Error occured',
      },
      title: 'Reset your password',
      passwordPlaceholder: 'Password',
      confirmButton: 'Confirm',
      haveAnAccount: 'Already have an account? Log in',
      quicklyAccess: 'or access quickly',
      googleSignIn: 'Sign-in with Google',
    },
  },
};

export type QuestionProperties = typeof en.questions.passport;

export default en;
