import { IIconProps } from '../interface';

const SvgVaticanCity = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_17010)">
      <path
        d="M28 0.835938C43.4639 0.835938 56 13.3721 56 28.8359C56 44.2998 43.4639 56.8359 28 56.8359C28 55.6186 24.3479 28.8359 24.3479 28.8359L28 0.835938Z"
        fill="#F0F0F0"
      />
      <path
        d="M28 56.8359C12.5361 56.8359 0 44.2998 0 28.8359C0 13.3721 12.5361 0.835938 28 0.835938"
        fill="#FFDA44"
      />
      <path
        d="M38.722 25.2008L43.9842 32.1621C43.1551 33.4175 43.1595 35.1089 44.1184 36.3773C45.3346 37.9863 47.6251 38.3048 49.2342 37.0885C50.8432 35.8722 51.1617 33.5818 49.9452 31.9728C48.9866 30.7044 47.3604 30.2389 45.9265 30.6941L37.7254 19.8448L35.7831 21.3131L32.8694 23.5153L35.8059 27.3997L38.722 25.2008ZM46.2977 33.2038C46.8341 32.7982 47.5976 32.9046 48.0031 33.4407C48.4084 33.9771 48.3024 34.7406 47.7661 35.1462C47.2298 35.5514 46.4662 35.4455 46.0609 34.9091C45.6552 34.3727 45.7614 33.6091 46.2977 33.2038Z"
        fill="#ACABB1"
      />
      <path
        d="M47.7478 27.3996L50.6843 23.5152L47.7707 21.3128L45.8285 19.8445L37.6274 30.6938C36.1937 30.2386 34.5674 30.7042 33.6086 31.9725C32.3922 33.5816 32.7107 35.8719 34.3197 37.0882C35.9288 38.3044 38.2191 37.986 39.4354 36.377C40.3942 35.1086 40.3985 33.4172 39.5696 32.1618L44.8317 25.2005L47.7478 27.3996ZM37.4931 34.909C37.0878 35.4455 36.3243 35.5514 35.7879 35.1461C35.2516 34.7405 35.1455 33.977 35.5509 33.4406C35.9563 32.9045 36.7198 32.7982 37.2563 33.2037C37.7926 33.6092 37.8986 34.3727 37.4931 34.909Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_17010">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgVaticanCity;
