import React, { useMemo } from 'react';
import { colors, typography } from '../theme';
import JsxParser from 'react-jsx-parser';
import '../theme/fonts/index.css';
import './index.css';
export type variantList = keyof typeof typography;

export type colorTypeList = keyof typeof colors;
export type colorBaseList = keyof typeof colors.base;
export type colorNeutralList = keyof typeof colors.neutral;

interface ITypography {
  children: string;
  variant: variantList;
  className?: string;
  colorType?: colorTypeList;
  color?: colorNeutralList | colorBaseList;
  htmlFor?: string;
  wrap?: boolean;
  clip?: boolean;
  pointer?: boolean;
}

const buildTag = (variant: string) => {
  if (variant.search('HeadingDefault') > -1) {
    return 'h1';
  }

  if (variant.search('HeadingSmall') > -1) {
    return 'h2';
  }

  if (variant.search('Label') > -1) {
    return 'label';
  }

  return 'p';
};

const Typography = ({
  children,
  variant,
  className,
  colorType = 'base',
  color,
  htmlFor,
  wrap,
  clip,
  pointer,
}: ITypography) => {
  const CustomTag = buildTag(variant);

  const colorBase: { [key: string]: string } = useMemo(
    () => colors[colorType],
    [colorType]
  );

  const typographyColor = color ? colorBase[color] : colors.base.black;

  const handleText = (text: string): string => {
    const formatedString = document.createElement(CustomTag);
    formatedString.innerHTML = text;

    const tooltips = formatedString.querySelectorAll('span');
    tooltips.forEach((tooltip) => {
      tooltip.replaceWith(`<span className="relative inline-flex justify-center">
          <span className="tooltip peer">${tooltip.innerText}</span>
          <div className="tooltipContent">
            <div className="arrow-up"></div>
            <span className="tooltip-text">${tooltip.getAttribute(
              'data-tooltip'
            )}</span>
          </div>
        </span>`);
    });

    return formatedString.innerText;
  };

  return (
    <CustomTag
      style={{ ...typography[variant], color: typographyColor }}
      className={`relative ${className} ${wrap ? 'break-words' : ''} ${
        pointer ? 'cursor-pointer select-none' : 'cursor-default'
      } ${clip ? 'd-block max-w-full' : ''}`}
      htmlFor={CustomTag === 'label' ? htmlFor : ''}
    >
      <JsxParser
        className={`${
          clip
            ? 'max-w-full whitespace-nowrap overflow-hidden text-ellipsis text-left'
            : ''
        }`}
        jsx={handleText(children)}
      />
    </CustomTag>
  );
};

export default Typography;
