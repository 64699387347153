import { IIconProps } from '../interface';

const SvgGlobePin = ({ width = 24, height = 24, color, type }: IIconProps) => {
  switch (type) {
    case 'circle':
      console.warn('Invalid option for this icon');
      return null;
    case 'md':
      console.warn('Invalid option for this icon');
      return null;
    case 'sm':
      console.warn('Invalid option for this icon');
      return null;
    case 'filled':
      console.warn('Invalid option for this icon');
      return null;
    default:
      return (
        <svg
          width={width}
          height={height}
          id="Layer_3"
          data-name="Layer 3"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M0,24H24V0H0Z" fill="none" />
          <path d="M0,0H24V24H0Z" fill="none" />
          <path
            d="M15,5.5c0,1.63851,1.788,3.38881,2.808,4.25435a1.09613,1.09613,0,0,0,1.383,0C20.212,8.88881,22,7.13851,22,5.5"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M18.50019,5.45A.05.05,0,1,1,18.45,5.5a.04973.04973,0,0,1,.05019-.05"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M15,5.5h0A3.5,3.5,0,0,1,18.5,2h0A3.5,3.5,0,0,1,22,5.5h0"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            x1="3.51401"
            y1="16"
            x2="20.48721"
            y2="16"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M12.00562,4C9.79493,4,8.00281,8.02944,8.00281,13s1.79212,9,4.00281,9"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            x1="12.00562"
            y1="10"
            x2="3.51404"
            y2="10"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M21,13a9,9,0,1,1-9-9"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M16.00281,13c0,4.97056-1.79212,9-4.00281,9"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </svg>
      );
  }
};

export default SvgGlobePin;
