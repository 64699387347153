import { IIconProps } from '../interface';

const SvgJersey = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15913)">
      <path
        d="M28 56.418C43.464 56.418 56 43.8819 56 28.418C56 12.954 43.464 0.417969 28 0.417969C12.536 0.417969 0 12.954 0 28.418C0 43.8819 12.536 56.418 28 56.418Z"
        fill="#F0F0F0"
      />
      <path
        d="M50.2138 45.4668L33.165 28.4181H33.1649L50.2138 11.369C49.4785 10.4135 48.6741 9.49421 47.799 8.61888C46.9237 7.74366 46.0045 6.93921 45.0489 6.2041L28.0001 23.2529L28 23.253L10.9511 6.2041C9.99576 6.93932 9.07635 7.74355 8.20102 8.61888C7.3257 9.49421 6.52135 10.4135 5.78624 11.369L22.8351 28.4177L22.8352 28.4178L5.78613 45.4669C6.52146 46.4224 7.3258 47.3417 8.20091 48.217C9.07624 49.0922 9.99543 49.8967 10.951 50.6318L28 33.583L28.0001 33.5828L45.0489 50.6317C46.0043 49.8965 46.9237 49.0921 47.799 48.2169C48.6744 47.3416 49.4786 46.4223 50.2138 45.4668Z"
        fill="#D80027"
      />
      <path
        d="M23.1304 8.93972L28 10.1571L32.8696 8.93972V4.80064L30.9217 5.77452L28 2.85278L25.0782 5.77452L23.1304 4.80064V8.93972Z"
        fill="#FFDA44"
      />
      <path
        d="M23.1304 8.9397V11.9834C23.1304 15.7109 28 16.853 28 16.853C28 16.853 32.8696 15.7108 32.8696 11.9834V8.9397H23.1304Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15913">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgJersey;
