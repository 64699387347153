import { IIconProps } from '../interface';

const SvgJordan = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15918)">
      <path
        d="M28 56.418C43.464 56.418 56 43.8819 56 28.418C56 12.954 43.464 0.417969 28 0.417969C12.536 0.417969 0 12.954 0 28.418C0 43.8819 12.536 56.418 28 56.418Z"
        fill="#F0F0F0"
      />
      <path
        d="M17.0434 18.6789H54.2583C50.3022 8.01647 40.0389 0.417969 28 0.417969C20.2678 0.417969 13.2686 3.55255 8.2019 8.61989L17.0434 18.6789Z"
        fill="black"
      />
      <path
        d="M17.0434 38.157H54.2583C50.3022 48.8195 40.0389 56.418 28 56.418C20.2678 56.418 13.2686 53.2834 8.2019 48.216L17.0434 38.157Z"
        fill="#6DA544"
      />
      <path
        d="M8.20099 8.6189C-2.73366 19.5536 -2.73366 37.2822 8.20099 48.217C12.7196 43.6984 17.0654 39.3526 28 28.418L8.20099 8.6189Z"
        fill="#D80027"
      />
      <path
        d="M11.1131 22.3311L12.6497 25.5444L16.12 24.7423L14.5658 27.9471L17.3565 30.1602L13.882 30.9432L13.8916 34.5049L11.1131 32.2766L8.33441 34.5049L8.34414 30.9432L4.86963 30.1602L7.66022 27.9471L6.10622 24.7423L9.57636 25.5444L11.1131 22.3311Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15918">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgJordan;
