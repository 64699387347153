import { IIconProps } from '../interface';

const SvgUganda = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16907)">
      <path
        d="M48.868 47.0846C53.3024 42.1307 56 35.5899 56 28.418C56 21.246 53.3023 14.7051 48.8679 9.75126L28 7.72235L7.13212 9.75126C2.69773 14.7051 0 21.246 0 28.418C0 35.5899 2.69763 42.1307 7.13202 47.0846L28 49.1136L48.868 47.0846Z"
        fill="#FFDA44"
      />
      <path
        d="M7.13208 9.75127H48.8679C43.741 4.02362 36.292 0.417969 28 0.417969C19.7079 0.417969 12.2589 4.02362 7.13208 9.75127Z"
        fill="black"
      />
      <path
        d="M0 28.4179L28 30.8527L56 28.4179C56 25.145 55.4366 22.004 54.4044 19.0845H1.59556C0.563391 22.004 0 25.145 0 28.4179H0Z"
        fill="#D80027"
      />
      <path
        d="M1.59556 37.7513H54.4047C55.4366 34.8318 56 31.6909 56 28.418H0C0 31.6909 0.563391 34.8318 1.59556 37.7513Z"
        fill="black"
      />
      <path
        d="M48.868 47.0845H7.13208C12.2589 52.8123 19.7079 56.4179 28.0001 56.4179C36.2922 56.4179 43.7412 52.8123 48.868 47.0845Z"
        fill="#D80027"
      />
      <path
        d="M37.3434 28.418C37.3434 33.5786 33.1606 37.7615 28 37.7615C22.8393 37.7615 18.6565 33.5786 18.6565 28.418C18.6565 23.2574 22.8393 19.0745 28 19.0745C33.1606 19.0745 37.3434 23.2574 37.3434 28.418Z"
        fill="#F0F0F0"
      />
      <path
        d="M31.4229 28.8944L28 27.4112C28 27.4112 28.8009 24.753 28.8435 24.5711C28.8747 24.4382 28.8913 24.2997 28.8913 24.1573C28.8913 23.6529 28.6869 23.1964 28.3564 22.8661L29.2171 22.0053C28.6664 21.4546 27.9055 21.1139 27.0651 21.1139C26.2247 21.1139 25.4637 21.4545 24.913 22.0053L25.7738 22.8661C25.4433 23.1965 25.239 23.653 25.239 24.1573C25.239 24.3417 25.2669 24.5194 25.3177 24.6873L24.0217 25.9832H26.3772C26.3772 25.9832 25.4034 27.4459 24.8717 28.6472C24.34 29.8484 24.879 31.3148 26.1077 31.861L26.8182 32.1767L28 33.2875V34.5049L26.7827 35.7222H29.2175V33.2874L30.3284 32.1765H32.6262C32.6372 32.1537 32.6488 32.1313 32.6593 32.1079C33.2052 30.8793 32.6517 29.4404 31.4229 28.8944Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16907">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.417969)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgUganda;
