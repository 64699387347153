import { IIconProps } from '../interface';

const SvgLaos = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_15977)">
      <path
        d="M53.2216 16.6621C48.6926 7.29617 39.1009 0.835938 28 0.835938C16.8991 0.835938 7.30732 7.29617 2.77832 16.6621L28 19.0969L53.2216 16.6621Z"
        fill="#D80027"
      />
      <path
        d="M2.77832 41.0098C7.30732 50.3757 16.8991 56.8359 28 56.8359C39.1009 56.8359 48.6926 50.3757 53.2216 41.0098L28 38.575L2.77832 41.0098Z"
        fill="#D80027"
      />
      <path
        d="M53.2217 16.662H2.77834C0.998375 20.3431 0 24.4728 0 28.8359C0 33.199 0.998375 37.3288 2.77834 41.0098H53.2215C55.0017 37.3288 56 33.199 56 28.8359C56 24.4728 55.0017 20.3431 53.2217 16.662Z"
        fill="#0052B4"
      />
      <path
        d="M27.9999 38.575C33.3787 38.575 37.739 34.2147 37.739 28.8359C37.739 23.4572 33.3787 19.0969 27.9999 19.0969C22.6212 19.0969 18.2609 23.4572 18.2609 28.8359C18.2609 34.2147 22.6212 38.575 27.9999 38.575Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_15977">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLaos;
