export const firstCategories = [
  {
    category_id: 'category_1',
    questions: [
      'countries',
      'passport',
      'previous_tax_return',
      'historic_ties',
      'permanent_home',
      'immigration',
      'recap',
    ],
  },
];

export const lastCategories = [
  {
    category_id: 'category_2',
    questions: ['personal_ties', 'personal_description'],
  },
];
