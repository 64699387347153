import { IIconProps } from '../interface';

const SvgUzbekistán = ({ width = 56, height = 56 }: IIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_172_16963)">
      <path
        d="M1.32213 20.3143C0.464406 23.0014 -0.000109356 25.8641 1.93107e-08 28.8357C-0.000109356 31.8074 0.464516 34.6703 1.32223 37.3575L28 38.575L54.6778 37.3577C55.5354 34.6704 56.0001 31.8074 56.0001 28.8359C56 25.8642 55.5355 23.0014 54.6778 20.3142L28 19.0969L1.32213 20.3143Z"
        fill="#F0F0F0"
      />
      <path
        d="M54.6776 20.3143L27.9999 19.097L1.322 20.3143C1.06781 21.111 0.848949 21.9233 0.665527 22.7491H55.3338C55.1506 21.9232 54.9318 21.1108 54.6776 20.3143Z"
        fill="#D80027"
      />
      <path
        d="M0.665894 34.9228C0.849097 35.7486 1.06796 36.5608 1.32214 37.3575L1.32477 37.3576L27.9998 38.5749L54.6776 37.3576C54.9317 36.5609 55.1506 35.7486 55.3339 34.9228H0.665894Z"
        fill="#D80027"
      />
      <path
        d="M27.9999 56.8358C40.4923 56.8358 51.0722 48.6542 54.6777 37.3575H1.32227C4.92781 48.6541 15.5079 56.8358 27.9999 56.8358Z"
        fill="#6DA544"
      />
      <path
        d="M27.9999 0.83606C15.5078 0.836169 4.92791 9.01774 1.32214 20.3143L54.6776 20.3144C51.0722 9.01763 40.4923 0.83595 27.9999 0.83606Z"
        fill="#338AF3"
      />
      <path
        d="M12.7954 12.4013C12.7954 9.77877 14.6384 7.58799 17.0997 7.05052C16.7215 6.96783 16.3289 6.9231 15.9258 6.9231C12.9002 6.9231 10.4475 9.37572 10.4475 12.4014C10.4475 15.427 12.9 17.8796 15.9258 17.8796C16.3289 17.8796 16.7214 17.8348 17.0997 17.7522C14.6385 17.2145 12.7954 15.0237 12.7954 12.4013Z"
        fill="#F0F0F0"
      />
      <path
        d="M20.3377 14.8914L20.7084 16.0327H21.9085L20.9377 16.7382L21.3085 17.8795L20.3377 17.1741L19.3666 17.8795L19.7375 16.7382L18.7666 16.0327H19.9667L20.3377 14.8914Z"
        fill="#F0F0F0"
      />
      <path
        d="M24.1679 14.8914L24.5388 16.0327H25.7388L24.7679 16.7382L25.1388 17.8795L24.1679 17.1741L23.197 17.8795L23.5679 16.7382L22.597 16.0327H23.797L24.1679 14.8914Z"
        fill="#F0F0F0"
      />
      <path
        d="M27.9984 14.8914L28.3692 16.0327H29.5694L28.5985 16.7382L28.9692 17.8795L27.9984 17.1741L27.0274 17.8795L27.3984 16.7382L26.4274 16.0327H27.6275L27.9984 14.8914Z"
        fill="#F0F0F0"
      />
      <path
        d="M31.8288 14.8914L32.1996 16.0327H33.3996L32.4288 16.7382L32.7997 17.8795L31.8288 17.1741L30.8577 17.8795L31.2287 16.7382L30.2577 16.0327H31.4578L31.8288 14.8914Z"
        fill="#F0F0F0"
      />
      <path
        d="M35.6591 14.8914L36.03 16.0327H37.2301L36.2592 16.7382L36.6301 17.8795L35.6591 17.1741L34.6882 17.8795L35.0591 16.7382L34.0883 16.0327H35.2882L35.6591 14.8914Z"
        fill="#F0F0F0"
      />
      <path
        d="M24.1679 10.9072L24.5388 12.0484H25.7388L24.7679 12.754L25.1388 13.8952L24.1679 13.1898L23.197 13.8952L23.5679 12.754L22.597 12.0484H23.797L24.1679 10.9072Z"
        fill="#F0F0F0"
      />
      <path
        d="M27.9984 10.9072L28.3692 12.0484H29.5694L28.5985 12.754L28.9692 13.8952L27.9984 13.1898L27.0274 13.8952L27.3984 12.754L26.4274 12.0484H27.6275L27.9984 10.9072Z"
        fill="#F0F0F0"
      />
      <path
        d="M31.8288 10.9072L32.1996 12.0484H33.3996L32.4288 12.754L32.7997 13.8952L31.8288 13.1898L30.8577 13.8952L31.2287 12.754L30.2577 12.0484H31.4578L31.8288 10.9072Z"
        fill="#F0F0F0"
      />
      <path
        d="M35.6591 10.9072L36.03 12.0484H37.2301L36.2592 12.754L36.6301 13.8952L35.6591 13.1898L34.6882 13.8952L35.0591 12.754L34.0883 12.0484H35.2882L35.6591 10.9072Z"
        fill="#F0F0F0"
      />
      <path
        d="M27.9984 6.92285L28.3692 8.06429H29.5694L28.5985 8.76965L28.9692 9.91109L27.9984 9.20573L27.0274 9.91109L27.3984 8.76965L26.4274 8.06429H27.6275L27.9984 6.92285Z"
        fill="#F0F0F0"
      />
      <path
        d="M31.8288 6.92285L32.1996 8.06429H33.3996L32.4288 8.76965L32.7997 9.91109L31.8288 9.20573L30.8577 9.91109L31.2287 8.76965L30.2577 8.06429H31.4578L31.8288 6.92285Z"
        fill="#F0F0F0"
      />
      <path
        d="M35.6591 6.92285L36.03 8.06429H37.2301L36.2592 8.76965L36.6301 9.91109L35.6591 9.20573L34.6882 9.91109L35.0591 8.76965L34.0883 8.06429H35.2882L35.6591 6.92285Z"
        fill="#F0F0F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_16963">
        <rect
          width="56"
          height={height}
          fill="white"
          transform="translate(0 0.835938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgUzbekistán;
