import React, { ChangeEvent, useState } from 'react';
import Icon from '../Icon';
import { colors } from '../theme';
import './index.css';

interface IInput {
  label: string;
  inputId: string;
  inputName: string;
  placeholder: string;
  disabled?: boolean;
  value?: string;
  hint?: string;
  variant: 'default' | 'small';
  error?: boolean;
  autocomplete?: boolean;
  errorMessage: string;
  type?: string;
  onInput: (value: ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({
  label,
  inputId,
  inputName,
  placeholder,
  disabled,
  value,
  hint,
  variant,
  error,
  errorMessage,
  type = 'text',
  autocomplete,
  onInput,
}: IInput) => {
  const [focus, setFocus] = useState(false);

  const onFocus = () => setFocus(true);
  const onBlur = () => setFocus(false);

  const showError = errorMessage && error && !disabled;
  const showHint = hint && !showError && !disabled;

  const bgColor = () => {
    if (disabled) {
      return 'bg-base-gray25';
    }

    if (error) {
      return 'bg-negative-field';
    }

    if (focus) {
      return 'bg-base-white';
    }

    return 'bg-neutral-field';
  };

  return (
    <div className="flex flex-col">
      <div className={`relative rounded-lg ${bgColor()}`}>
        <input
          id={inputId}
          name={inputName}
          onInput={onInput}
          disabled={disabled}
          placeholder={placeholder}
          className={`input ${variant}  ${error && !disabled ? 'error' : ''} ${
            variant === 'default'
              ? 'placeholder-transparent placeholder-shown:py-spacing-18 pt-spacing-26 pb-spacing-10'
              : 'placeholder-neutral-textHint'
          } ${Boolean(disabled) && 'disabled'} peer w-full`}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          type={type}
        />
        {Boolean(variant === 'default') && (
          <label
            htmlFor={inputId}
            className={`${
              disabled ? 'text-neutral-textDisabled' : 'text-neutral-textHint'
            } absolute text-xs top-spacing-8 left-spacing-16 transition-all 
          peer-placeholder-shown:top-spacing-16 peer-placeholder-shown:text-base pr-spacing-20 w-full block`}
          >
            {label}
          </label>
        )}
      </div>

      {showHint && (
        <div className="flex items-center pl-spacing-2 pt-spacing-2">
          <span className="text-neutral-textHint text-sm">{hint}</span>
        </div>
      )}

      {showError && (
        <div className="flex items-center pl-spacing-2 pt-spacing-2">
          <Icon
            name="Error"
            type="filled"
            color={colors.negative.textWeak}
            width={10}
            height={12}
          />
          <span className="text-negative-textWeak text-sm ml-spacing-5">
            {errorMessage}
          </span>
        </div>
      )}
    </div>
  );
};

export default Input;
