import React, { useState, ChangeEvent, MouseEvent } from 'react';
import Icon from '../Icon';
import { FlagsList } from '../Icon/flags/enum';
import { IconsList } from '../Icon/icons/enum';
import { colors } from '../theme';
import Typography from '../Typography';
import './index.css';
interface IRadio {
  variant:
    | 'default'
    | 'WithLabel'
    | 'WithLabelBorder'
    | 'WithLabelIcon'
    | 'WithIcon'
    | 'Horizontal';
  type: 'Radio' | 'Checkbox';
  fluid?: boolean;
  hideLabel?: boolean;
  name: string;
  disabled?: boolean;
  checked: boolean;
  label?: string;
  body?: string;
  icon?: IconsList | FlagsList;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent) => void;
}

interface IRadioVariants extends IRadio {
  focused: boolean;
  mouseDown: boolean;
  onFocus: () => void;
  onBlur: () => void;
  onMouseDown: () => void;
  onMouseUp: () => void;
}
interface IInputLabel {
  type: 'Radio' | 'Checkbox';
  checked: boolean;
  disabled?: boolean;
  name: string;
  allowFocus: boolean;
  hideLabel?: boolean;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  onBlur: () => void;
}

const InputLabel = ({
  checked,
  disabled,
  name,
  type,
  allowFocus,
  hideLabel,
  onChange,
  onFocus,
  onBlur,
}: IInputLabel) => {
  return (
    <>
      <input
        type="checkbox"
        checked={checked}
        id={name}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        className="peer absolute w-0 h-0"
      />
      {Boolean(!hideLabel) && (
        <label
          className={`${checked ? 'checked' : 'unchecked'} ${
            disabled
              ? 'disabled peer-active:border-neutral-textDisabled peer-focus:outline-none'
              : 'cursor-pointer'
          } ${type === 'Radio' ? 'radio' : 'checkbox'} ${
            allowFocus ? 'peer-focus:outline-2' : 'peer-focus:outline-none'
          } block w-spacing-20 h-spacing-20`}
          htmlFor={name}
        >
          {type === 'Checkbox' && checked && (
            <Icon
              name="Check"
              type="sm"
              color={colors.base.white}
              width={12}
              height={12}
            />
          )}
        </label>
      )}
    </>
  );
};

const HorizontalVariant = ({
  name,
  disabled,
  checked,
  label = '',
  body = '',
  type,
  focused,
  mouseDown,
  fluid,
  hideLabel,
  onChange,
  onFocus,
  onBlur,
  onMouseDown,
  onMouseUp,
}: IRadioVariants) => {
  return (
    <div
      className={`inputContainer ${fluid ? 'w-full' : 'w-spacing-140'} ${
        checked ? 'checked' : 'unchecked'
      } ${Boolean(disabled) && 'disabled'}
      ${Boolean(focused) && 'focused'} ${Boolean(mouseDown) && 'mouseDown'}`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <label
        className={`px-spacing-16 pb-spacing-16 pt-spacing-48 flex ${
          disabled ? 'cursor-default' : 'cursor-pointer'
        }`}
        htmlFor={!disabled ? name : ''}
      >
        <div className="absolute top-spacing-14 right-spacing-14">
          <InputLabel
            hideLabel={hideLabel}
            checked={checked}
            disabled={disabled}
            name={name}
            type={type}
            allowFocus={false}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </div>
        <div className="flex flex-col flex-1 overflow-hidden">
          <Typography
            pointer={true}
            variant="LabelDefault500"
            color={disabled ? 'gray400' : 'gray800'}
            clip={true}
            htmlFor={name}
          >
            {label}
          </Typography>
          <Typography
            pointer={true}
            variant="LabelXsmall500"
            color={disabled ? 'gray400' : 'gray500'}
            className="mt-spacing-4"
            clip={true}
            htmlFor={name}
          >
            {body}
          </Typography>
        </div>
      </label>
    </div>
  );
};

const WithIcon = ({
  name,
  disabled,
  checked,
  label = '',
  body = '',
  icon,
  type,
  focused,
  mouseDown,
  fluid,
  hideLabel,
  onChange,
  onFocus,
  onBlur,
  onMouseDown,
  onMouseUp,
}: IRadioVariants) => {
  return (
    <div
      className={`inputContainer ${fluid ? 'w-full' : 'w-spacing-195'} ${
        checked ? 'checked' : 'unchecked'
      } min-h-spacing-110 ${Boolean(disabled) && 'disabled'}
      ${Boolean(focused) && 'focused'} ${Boolean(mouseDown) && 'mouseDown'}`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <label
        className={`h-full px-spacing-16 pb-spacing-16 pt-spacing-32 flex flex-col items-center justify-end ${
          disabled ? 'cursor-default' : 'cursor-pointer'
        }`}
        htmlFor={!disabled ? name : ''}
      >
        <div className="absolute top-spacing-14 right-spacing-14">
          <InputLabel
            hideLabel={hideLabel}
            checked={checked}
            disabled={disabled}
            name={name}
            type={type}
            allowFocus={false}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </div>
        <Icon
          name={icon || 'Heart'}
          width={26}
          height={23}
          color={disabled ? colors.neutral.textDisabled : colors.neutral.text}
        />
        <div className="w-full flex justify-center overflow-hidden">
          <Typography
            pointer={true}
            variant="LabelDefault500"
            color={disabled ? 'gray400' : 'gray800'}
            className="mt-spacing-12 text-center"
            htmlFor={name}
            clip={true}
          >
            {label}
          </Typography>
        </div>
        <Typography
          pointer={true}
          variant="LabelXsmall500"
          color={disabled ? 'gray400' : 'pageActionStrongGray'}
          className="mt-spacing-4 text-center"
          htmlFor={name}
          clip={true}
        >
          {body}
        </Typography>
      </label>
    </div>
  );
};

const WithLabelIcon = ({
  name,
  disabled,
  checked,
  label = '',
  icon,
  type,
  focused,
  mouseDown,
  fluid,
  onChange,
  onFocus,
  onBlur,
  onMouseDown,
  onMouseUp,
}: IRadioVariants) => {
  return (
    <div
      className={`inputContainer h-spacing-110 ${
        fluid ? 'w-full' : 'w-spacing-195'
      } ${checked ? 'checked' : 'unchecked'} ${Boolean(disabled) && 'disabled'}
      ${Boolean(focused) && 'focused'} ${Boolean(mouseDown) && 'mouseDown'}`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <label
        className={`h-full px-spacing-16 pb-spacing-16 pt-spacing-18 flex flex-col justify-between ${
          disabled ? 'cursor-default' : 'cursor-pointer'
        }`}
        htmlFor={!disabled ? name : ''}
      >
        <InputLabel
          hideLabel={true}
          checked={checked}
          disabled={disabled}
          name={name}
          type={type}
          allowFocus={false}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <Icon
          name={icon || 'Heart'}
          width={24}
          height={24}
          color={disabled ? colors.neutral.textDisabled : colors.neutral.text}
        />
        <div className="w-full flex justify-center overflow-hidden">
          <Typography
            pointer={true}
            variant="LabelDefault500"
            color={disabled ? 'gray400' : 'gray800'}
            className="mt-spacing-12"
            htmlFor={name}
            clip={true}
          >
            {label}
          </Typography>
        </div>
      </label>
    </div>
  );
};

const WithLabel = ({
  variant,
  name,
  disabled,
  checked,
  label = '',
  body = '',
  type,
  focused,
  mouseDown,
  fluid,
  hideLabel,
  onChange,
  onFocus,
  onBlur,
  onMouseDown,
  onMouseUp,
}: IRadioVariants) => {
  return (
    <div
      className={`inputContainer ${fluid ? 'w-full' : 'w-spacing-192'} ${
        checked ? 'checked' : 'unchecked'
      } ${Boolean(disabled) && 'disabled'}
      ${Boolean(focused) && variant === 'WithLabelBorder' && 'focused'} ${
        Boolean(mouseDown) && 'mouseDown'
      } ${variant === 'WithLabelBorder' ? 'border' : 'border-0 outline-none'}`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <label
        className={`p-spacing-18 flex ${
          disabled ? 'cursor-default' : 'cursor-pointer'
        } ${!body ? 'items-center' : 'items-start'}`}
        htmlFor={!disabled ? name : ''}
      >
        <InputLabel
          hideLabel={hideLabel}
          checked={checked}
          disabled={disabled}
          name={name}
          type={type}
          allowFocus={variant === 'WithLabel'}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />

        <div
          className={`flex flex-col flex-1 overflow-hidden ${
            !hideLabel ? 'ml-spacing-18' : ''
          } `}
        >
          <Typography
            pointer={true}
            variant="LabelDefault500"
            color={disabled ? 'gray400' : 'gray800'}
            wrap={variant !== 'WithLabelBorder'}
            clip={variant === 'WithLabelBorder'}
            htmlFor={name}
          >
            {label}
          </Typography>
          {body && (
            <Typography
              pointer={true}
              variant="LabelXsmall500"
              color={disabled ? 'gray400' : 'gray500'}
              className="mt-spacing-4"
              wrap={variant !== 'WithLabelBorder'}
              clip={variant === 'WithLabelBorder'}
              htmlFor={name}
            >
              {body}
            </Typography>
          )}
        </div>
      </label>
    </div>
  );
};

const DefaultVariant = ({
  name,
  disabled,
  checked,
  type,
  onChange,
  onFocus,
  onBlur,
}: IRadioVariants) => {
  return (
    <div className="flex items-center">
      <InputLabel
        checked={checked}
        disabled={disabled}
        name={name}
        type={type}
        allowFocus={true}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  );
};

const Radio = (props: IRadio) => {
  const { variant, disabled } = props;
  const [focused, setFocused] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const onMouseDown = () => {
    if (!disabled) setMouseDown(true);
  };
  const onMouseUp = () => setMouseDown(false);

  switch (variant) {
    case 'Horizontal':
      return (
        <HorizontalVariant
          {...props}
          focused={focused}
          mouseDown={mouseDown}
          onBlur={onBlur}
          onFocus={onFocus}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        />
      );
    case 'WithIcon':
      return (
        <WithIcon
          {...props}
          focused={focused}
          mouseDown={mouseDown}
          onBlur={onBlur}
          onFocus={onFocus}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        />
      );
    case 'WithLabelIcon':
      return (
        <WithLabelIcon
          {...props}
          focused={focused}
          mouseDown={mouseDown}
          onBlur={onBlur}
          onFocus={onFocus}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        />
      );
    case 'WithLabelBorder':
    case 'WithLabel':
      return (
        <WithLabel
          {...props}
          focused={focused}
          mouseDown={mouseDown}
          onBlur={onBlur}
          onFocus={onFocus}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        />
      );
    default:
      return (
        <DefaultVariant
          {...props}
          focused={focused}
          mouseDown={mouseDown}
          onBlur={onBlur}
          onFocus={onFocus}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        />
      );
  }
};

export default Radio;
